import type { Article, Image } from '~/types/demegro'

export function useArticle() {
    const articleIsInCart = (article: Article | null | undefined): boolean => {
        return Boolean(article?.amount_in_cart?.length)
    }

    const getInCartAmount = (article: Article | null | undefined): number | null => {
        if (!article?.amount_in_cart)
            return null

        // Find the item with expiration 'no_exp'
        const item = article.amount_in_cart.find(([expiration, _]) => expiration === 'no_exp')
        return item ? item[1] : null
    }

    const getInCartAmountByExpiration = (article: Article | null | undefined, expiration: string): number | null => {
        if (!article?.amount_in_cart)
            return null

        // Normalize the expiration date to YYYY-MM-DD format
        const expirationDate = new Date(expiration).toISOString().split('T')[0]

        // Find the item with the matching expiration date
        const item = article.amount_in_cart.find(
            ([exp, _]) => new Date(exp).toISOString().split('T')[0] === expirationDate,
        )
        return item ? item[1] : null
    }

    const formatPriceToString = (price: number): string => {
        return Number(price).toFixed(2).replace('.', ',')
    }

    const formatPriceToNumber = (price: string | number): number => {
        if (typeof price === 'number') {
            return Number(Number(price).toFixed(2))
        }
        const normalized = price.replace(',', '.')
        return Number(Number(normalized).toFixed(2))
    }

    function formatPrice(price: number, keepNumber?: boolean): string | number
    function formatPrice(price: string): number
    function formatPrice(price: string | number, keepNumber: boolean = false): string | number {
        if (typeof price === 'number') {
            return keepNumber ? formatPriceToNumber(price) : formatPriceToString(price)
        }
        return formatPriceToNumber(price)
    }

    function getImage(article: Article): Image | undefined | null {
        if (!article.images)
            return null

        if (article.main_image)
            return article.main_image

        return article.images[0]
    }

    function getImages(article: Article): Image[] {
        if (!article.images)
            return []

        const imageMap = new Map()
        const orderedImages = []

        if (article.main_image) {
            imageMap.set(article.main_image.src, article.main_image)
            orderedImages.push(article.main_image)
        }

        article.images?.forEach((img) => {
            if (!imageMap.has(img.src)) {
                imageMap.set(img.src, img)
                orderedImages.push(img)
            }
        })

        return orderedImages
    }

    return {
        getInCartAmount,
        articleIsInCart,
        formatPrice,
        formatPriceToNumber,
        formatPriceToString,
        getInCartAmountByExpiration,
        getImage,
        getImages,
    }
}
